.section-files {
  width: 100%;
  min-height: 100vh;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-files {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
}

.card-files {
  background-color: white;
  border-radius: 15px;
  padding: 5px;
  margin: 10px;
  box-shadow: 0 0 10px rgb(59, 59, 59);
  width: 300px;
}

.items-file {
  background-color: #000000;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: space-between;
}
.list-items{
  text-align: left;
  height: 100%
}
.header-item{
  text-align: center;
}

.type-file {
  font-size: 70px;
}

.btn-disabled {
  text-decoration: none;
  color: white;
  background-color: #828282;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 10px;
}
.btn-disabled:hover {
  color: white;
  background-color: #828282;
}
.btn-download {
  text-decoration: none;
  color: white;
  background-color: #828282;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 10px;
}

.btn-download:hover {
  background-color: white;
  color: green;
}
.btn-download2 {
  text-decoration: none;
  color: #9d24a1;
  background-color: white;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.form-check-input:checked {
  background-color: #686868;
  border-color: #686868;
}

#upload_button {
  display: flex;
  flex-direction: row;
  text-align: center;
}
#upload_button input[type=file] {
  display:none;
}
#upload_button input[type=text] {
  background-color: lightGray;
  width: 100%;
}
#upload_button label {
  display: flex; 
  flex-direction: row;
  width: 100%;
}

.btn-choosen {
  text-decoration: none;
  color: white;
  background-color: gray;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  width: 15%;
  height: 100%;
  margin-left: 1%;
}

.btn-choosen:hover {
  /* background-color: rgb(169, 169, 167); */
  color: white;
}